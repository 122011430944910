@value bp-tablet-small-viewport from '../../../../../ui/src/lib/styles/values/breakpoints.css';

.container {
  --dex-search-input-height: 64px;
  --dex-search-margins: 0px;
  --dex-search-key-control-height: 0px;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.container * {
  box-sizing: border-box;
}

.dialog {
  --modal-dialog-border-radius: 0px;
  --modal-dialog-background-color: transparent;
  --modal-dialog-width: 100vw;
  --modal-dialog-maximum-width-size: 100vw;
  --modal-dialog-height: 100vh;

  width: var(--modal-dialog-width);
  height: var(--modal-dialog-height);
  padding: 0;
  align-self: normal;
}

.top-container {
  display: inline-flex;
  align-items: center;

  box-sizing: border-box;
  height: var(--dex-search-input-height);
}

.bottom-container {
  height: 100vh;
}

.input {
  flex: 1;
}

.filter {
  margin-left: auto;

  /* Market overrides to fit into the search box */
  --button-medium-size-tertiary-rank-vertical-padding: 4px;
  --button-medium-size-tertiary-rank-horizontal-padding: calc(
    1 * var(--dex-spacing-1x)
  );
  --button-medium-size-minimum-height: calc(
    4 * var(--dex-spacing-1x) -
      calc(2 * var(--button-medium-size-tertiary-rank-vertical-padding))
  );
  --trigger-bottom-padding: 0px;
}

.background {
  background-color: var(--core-surface-5-color);
}

.hide-mobile {
  display: none;
}

@media only screen and bp-tablet-small-viewport {
  .container {
    --dex-search-margins: 80px;
    --dex-search-key-control-height: 82px;

    margin: calc(5 * var(--dex-spacing-1x)) 0;
  }

  .hide-mobile {
    display: flex;
  }

  .top-container {
    border-top-left-radius: var(--dex-border-radius);
    border-top-right-radius: var(--dex-border-radius);
  }

  .bottom-container {
    height: auto;
    border-bottom-left-radius: var(--dex-border-radius);
    border-bottom-right-radius: var(--dex-border-radius);
  }

  .dialog {
    --modal-dialog-width: 600px;
    --modal-dialog-maximum-width-size: 600px;
    --modal-dialog-height: auto;
  }

  .mobile-close-button {
    display: none;
  }
}
