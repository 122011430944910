@value bp-mobile-small-viewport from '../../../../../ui/src/lib/styles/values/breakpoints.css';

.container {
  display: inline-block;
  align-items: center;
}

.primary {
  background-color: var(--core-blue-fill-color);
  color: var(--core-text-inverse-color);
  text-decoration: none;

  display: flex;
  align-items: center;
}

.secondary {
  background-color: var(--core-fill-40-color);
  color: var(--core-emphasis-text-color);
  text-decoration: none;

  display: flex;
  align-items: center;
}

.primary *:last-child {
  margin-left: calc(0.75 * var(--dex-spacing-1x));
}

.primary:hover {
  background-color: var(--core-blue-10-color);
}

.secondary:hover {
  background-color: var(
    --button-normal-variant-secondary-rank-hover-state-background-color
  );
}

@media only screen and bp-mobile-small-viewport {
  .container {
    display: inline-flex;
  }

  .secondary {
    margin-left: calc(1.5 * var(--dex-spacing-1x));
    margin-top: 0;
  }
}
