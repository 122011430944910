@value bp-tablet-small-viewport from '../../../ui/src/lib/styles/values/breakpoints.css';

.layout,
.layout.flex {
  display: flex;
}

.layout.grid {
  display: grid;
  grid-template-columns: 1fr;
}

.row {
  flex-flow: row;
}

.col {
  flex-flow: column;
}

.wrap {
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.space-evenly {
  justify-content: space-evenly;
}

.start {
  justify-content: start;
}

.end {
  justify-content: end;
}

.layout > p + p:has(> img) {
  margin-top: 0;
}

.layout:has(> p + p) {
  display: flex;
  gap: calc(2 * var(--dex-spacing-1x));
  justify-content: initial;
}

/* Ensure icons fit properly */
.layout > p:has(> img) {
  display: flex;
}

@media only screen and bp-tablet-small-viewport {
  .layout.grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
