@value bp-tablet-small-viewport from '../styles/values/breakpoints.css';

.doc-card {
  text-decoration: none;
  transition: box-shadow 0.1s;
}

.doc-card {
  position: relative;
  color: var(--core-text-10-color);
}

.doc-card.small .content p:first-of-type + p {
  margin-top: calc(0.5 * var(--dex-spacing-1x));
}

/* Only show hovers on computers, not mobile devices */
@media (hover: hover) and (pointer: fine) {
  .doc-card.border.shadow:hover {
    box-shadow: var(--core-elevation-20-color);
  }

  .doc-card.darken:hover {
    background-color: var(--core-fill-50-color);
  }
}

.doc-card.border.shadow:has(> a:focus-visible) {
  box-shadow: var(--core-elevation-20-color);
}

.doc-card.darken:has(> a:focus-visible) {
  background-color: var(--core-fill-50-color);
}

.doc-card-link-out {
  display: flex;
  flex-flow: row;
  align-items: center;
  color: var(--core-emphasis-text-color);
  text-decoration: none;

  margin-top: calc(-1 * var(--dex-spacing-1x));
}

.link-out-icon {
  transition: transform 0.15s ease-out;
  margin-left: calc(0.75 * var(--dex-spacing-1x));
}

a.doc-card:hover .link-out-icon,
.doc-card:has(> a:focus-visible) .link-out-icon,
.doc-card-link-out:hover .link-out-icon,
.doc-card-link-out:focus-visible .link-out-icon {
  transform: translateX(calc(0.75 * var(--dex-spacing-1x)));
}

/* The next section of CSS is all to allow having a clickable card
with clickable anchors inside it. It is against the HTML spec to have
anchors within anchors. Therefore, we work around it by making the whole card
covered by an anchor, and then raising the anchors in the content above the card.
See: https://stackoverflow.com/a/52076345 */
.doc-card-anchor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.content {
  position: relative;
  pointer-events: none;
}

/* These are to style cards that live inside a grid layout
  It helps ensure the cards fill the entire space, and that the last
  important elements are aligned to the bottom of the card */
.in-grid .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Ensure there's enough space for the tallest card, otherwise,
the margin-top: auto makes the content bleed into it's previous sibling */
.in-grid
  .content
  > p:has(+ div:last-child:not([data-name='icon-container-grid'])),
.in-grid .content > p:has(+ p:last-of-type > .doc-card-link-out) {
  margin-bottom: calc(2 * var(--dex-spacing-1x));
}

/* Align the last piece of content that isn't text to the end of the card */
.in-grid .content > div:last-child:not([data-name='icon-container-grid']),
.in-grid .content > p:last-of-type:has(> .doc-card-link-out) {
  margin-top: auto;
}

.content > img {
  /* Have to use 7px padding in the end */
  box-sizing: content-box;
  padding: 7px;
  margin-bottom: var(--dex-spacing-1x);
  background-color: var(--core-surface-5-color);
}

.content > img:has(+ h3) {
  margin-bottom: 0;
}

.content.border > img {
  border: var(--dex-border-line);
  border-radius: 6px;
}

.content a {
  pointer-events: all;
}

@media only screen and bp-tablet-small-viewport {
  .content > img:has(+ h3) {
    margin-bottom: calc(-0.75 * var(--dex-spacing-1x));
  }
}
