@value bp-desktop-medium-viewport, bp-tablet-small-viewport from '../../../ui/src/lib/styles/values/breakpoints.css';

.desktop,
.desktop-flex,
.desktop-grid {
  display: none !important;
}

.tablet,
.tablet-flex,
.tablet-grid {
  display: none !important;
}

.narrow {
  display: block !important;
}

.narrow-flex {
  display: flex !important;
}

.narrow-grid {
  display: grid !important;
}

@media only screen and bp-tablet-small-viewport {
  .narrow,
  .narrow-flex,
  .narrow-grid {
    display: none !important;
  }

  .tablet {
    display: block !important;
  }

  .tablet-flex {
    display: flex !important;
  }

  .tablet-grid {
    display: grid !important;
  }
}

@media only screen and bp-desktop-medium-viewport {
  .tablet,
  .tablet-flex,
  .tablet-grid {
    display: none !important;
  }

  .desktop {
    display: block !important;
  }

  .desktop-flex {
    display: flex !important;
  }

  .desktop-grid {
    display: grid !important;
  }
}
