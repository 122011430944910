@value bp-tablet-small-viewport from '../styles/values/breakpoints.css';

.heading {
  --heading-anchor-size: 40px;
  --tooltip-min-height: 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: calc(-1 * var(--heading-anchor-size));
}

.heading h4 {
  font-size: var(--core-type-semibold-30-size);
  line-height: var(--core-type-semibold-30-leading);
  font-weight: var(--core-type-semibold-30-weight);
}

.baseline {
  align-items: baseline;
}

.container {
  display: inline-flex;
  align-items: center;

  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.heading:hover .container,
.container:has(.trigger:focus-visible) {
  opacity: 1;
}

.anchor {
  display: block;
  position: relative;
  visibility: hidden;

  /* this CSS var ensures that we can shift the anchor headings when we have a fixed header that pushes the content */
  top: var(--anchor-heading-top, 0);
}

.trigger.button {
  justify-content: flex-end;
  padding-right: calc(0.5 * var(--dex-spacing-1x));
  width: var(--heading-anchor-size);
}

.icon path {
  fill: var(--core-emphasis-fill-color);
}

/* Market adds the class name after hydration, so need
to target the actual element */
.container market-tooltip:not([hydrated]) {
  margin-right: var(--heading-anchor-size);
}

/* Ensure the non-hydrated tooltip elements don't shift the content */
.container market-tooltip:not([hydrated]) * {
  display: none;
}

.tooltip {
  --tooltip-minimum-width: 0px;
  --tooltip-minimum-height: 0px;
}

.tooltip[hydrated] {
  display: flex;
}

@media only screen and bp-tablet-small-viewport {
  .trigger.button {
    justify-content: center;
    padding-right: 0px;
  }
}
