.aside {
  border-left: 6px solid var(--aside-color, black);

  display: flex;
  flex-flow: column nowrap;
}

.info {
  --aside-color: var(--core-blue-fill-color);
}

.warning {
  --aside-color: var(--core-red-fill-color);
}

.success {
  --aside-color: var(--core-green-fill-color);
}

.important {
  --aside-color: var(--core-gold-fill-color);
}

.tip {
  --aside-color: var(--core-purple-fill-color);
}

.aside [data-testid="aside-content"] p {
  margin-top: var(--dex-spacing-1x);
}

.aside [data-testid="aside-content"] p:first-child {
  margin-top: 0;
}

.aside [data-testid="aside-content"] ul {
  margin-top: var(--dex-spacing-1x);
}

.aside ul:first-child {
  margin-top: 0;
}

.aside ul:last-child {
  margin-bottom: 0;
}

.aside ul li:last-child {
  margin-bottom: 0;
}
