.trigger {
  color: var(--core-text-20-color);
}

.trigger:hover,
.trigger:hover span,
.selected {
  color: var(--core-emphasis-text-color);
}

.icon {
  transition: transform 0.25s;
}

.icon.open {
  transform: rotate(-180deg);
}

.dropdown {
  --modal-popover-background-color: var(--core-surface-5-color);
}
