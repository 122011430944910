.container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container:after {
  padding-top: 394px;
  display: block;
  content: '';
}
