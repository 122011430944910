.tabset {
  position: relative;

  display: flex;
  align-items: center;

  white-space: nowrap;
  /* Allow horizontal scrolling if it overflows */
  overflow-y: hidden;
  overflow-x: auto;
}

.tabset-underline {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.tabset-item {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tabset-item {
  margin: 0;
}

.tabset-item:not(.last) {
  margin-right: calc(2 * var(--dex-spacing-1x));
}

.tabset-item .selected {
  color: var(--core-emphasis-text-color);
}

.tabset-item.small .selected {
  margin-bottom: -1px;
}

.underline {
  height: 2px;
  width: 100%;
  background-color: var(--core-emphasis-text-color);
}
