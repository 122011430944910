.accordion {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  border-bottom: var(--dex-border-line);
  border-radius: 0;
  border-color: var(--core-fill-40-color);
}

.container {
  display: grid;
  grid-template-rows: 0fr;
  transition: all 0.25s ease-in-out;
}

.container.expanded {
  grid-template-rows: 1fr;
}

.content {
  overflow: hidden;
}
