@value bp-desktop-medium-viewport from '../../../../shared/ui/src/lib/styles/values/breakpoints.css';

.desktop {
  display: none;
}

.narrow {
  display: block;
}

@media only screen and bp-desktop-medium-viewport {
  /* Even though the disply is none, it's still in the document. So
  we need to ensure paragraph spacing is kept */
  p + .narrow + p {
    margin-top: calc(2 * var(--dex-spacing-1x));
  }

  .narrow {
    display: none;
  }

  .desktop {
    display: block;
  }
}
