.button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.activity-indicator {
  /* Adjusts indicator position relative to dropdown chevron */
  height: calc(3 * var(--dex-spacing-1x));
}

.select-code-option {
  padding: var(--dex-spacing-1x);
}

.select-code-row {
  display: flex;
  align-items: center;
  gap: var(--dex-spacing-1x);
}

.code-lang-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Set width to the widest Icon (Go) so all icons align horizontally */
  width: calc(4.5 * var(--dex-spacing-1x));
  height: calc(3 * var(--dex-spacing-1x));
}

/* Selected items */
.select-code-option[data-selected] p {
  color: var(--core-emphasis-fill-color);
}
.select-code-option[data-selected] path,
.select-code-option[data-selected] circle {
  fill: var(--core-emphasis-fill-color);
}
/* Set stroke color of only cURL icon */
.select-code-option[data-selected] path:global(.curl) {
  stroke: var(--core-emphasis-fill-color);
}

/* Hover state for unselected items */
.select-code-option:hover:not([data-selected]) path:not(:global(.fill-background)),
.select-code-option:hover:not([data-selected]) circle:not(:global(.fill-background)) {
  fill: var(--core-text-20-color);
}
.select-code-option:hover:not([data-selected]) path:global(.curl) {
  stroke: var(--core-text-20-color);
}

/* Base state for items */
.code-lang-icon path, .code-lang-icon circle {
  fill: var(--core-fill-20-color);
}
.code-lang-icon path:global(.curl) {
  stroke: var(--core-fill-20-color);
}
.code-lang-icon path:global(.fill-background) {
  fill: var(--core-fill-inverse-color);
}