.container {
  --carousel-visible-edge-px: calc(1.5 * var(--dex-spacing-1x));

  /* Create new stacking context */
  position: relative;
  z-index: 0;
}

/* Ensure the shims cover the shifted carousel margin/padding */
.container.shims::before {
  left: calc(-1.5 * var(--dex-spacing-1x));
}

.container.shims::after {
  right: calc(-1.5 * var(--dex-spacing-1x));
}

.carousel {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-x: auto;

  /* Allow the box shadow to be visible on the edges */
  margin: calc(-1 * var(--carousel-visible-edge-px));
  padding: var(--carousel-visible-edge-px);
}

.card {
  --card-width: 256px;

  display: block;

  min-width: var(--card-width);
  width: var(--card-width);

  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;

  text-decoration: none;
}

.card:hover,
.card:focus-visible {
  box-shadow: var(--core-elevation-20-color);
}

.card + .card {
  margin-left: calc(2.5 * var(--dex-spacing-1x));
}

.carousel-button-container {
  --carousel-button-dims: calc(6 * var(--dex-spacing-1x));

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 0;

  z-index: 2;

  cursor: pointer;

  /* Grow the hit target a little without moving the actual button */
  margin: 0 calc(-1 * var(--carousel-visible-edge-px));
  padding: 0 var(--carousel-visible-edge-px);
}

.carousel-button {
  background-color: var(--core-surface-5-color);

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--carousel-button-dims);
  height: var(--carousel-button-dims);

  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1),
    0px 8px 16px 0px rgba(0, 0, 0, 0.1);

  color: var(--core-text-10-color);
}

.carousel-button-container:hover .carousel-button,
.carousel-button:hover,
.carousel-button:focus-visible {
  color: var(--core-emphasis-fill-color);
}

.carousel-shim {
  position: absolute;
  top: 0;
  bottom: 0;
}

.carousel-button-container.left,
.carousel-shim.left {
  left: 0;
}

.carousel-button-container.right,
.carousel-shim.right {
  right: 0;
}
