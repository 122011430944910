.emptyresults {
  border-radius: 0px;
  border: 0;

  padding-bottom: calc(2 * var(--dex-spacing-1x));
}

.background {
  background-color: var(--core-surface-5-color);
}
