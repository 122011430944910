.cta-container {
  background-color: var(--core-sky-40-color);

  display: inline-flex;
  align-items: center;
}

.cta {
  background-color: var(--core-blue-fill-color);
  color: var(--core-text-inverse-color);
  text-decoration: none;

  display: flex;
  align-items: center;
}

.cta *:last-child {
  margin-left: calc(0.75 * var(--dex-spacing-1x));
}

.cta:hover {
  background-color: var(--core-blue-10-color);
}
