@value bp-tablet-small-viewport, bp-tablet-medium-viewport from '../../../../shared/ui/src/lib/styles/values/breakpoints.css';

.vars {
  --mobile-nav-shift: 256px;
  --mobile-nav-transition: opacity 0.3s ease, transform 0.4s ease;
  --header-nav-z-index: 1;
}

.navigation {
  bottom: 0;
  left: calc(-1 * var(--mobile-nav-shift));
  position: fixed;
  top: 0;
  opacity: 0;
  transition: var(--mobile-nav-transition);
  width: 260px;
  background-color: var(--dex-ui-app-color);
  z-index: 1;
}

.mobile-nav-open {
  opacity: 1;
  transform: translateX(var(--mobile-nav-shift));
  padding-left: calc(5 * var(--dex-spacing-1x));
}

.shifter {
  transition: var(--mobile-nav-transition);
}

.shift-contents {
  transform: translateX(var(--mobile-nav-shift));
  opacity: 0.3;
}

@media screen and bp-tablet-small-viewport {
  .navigation {
    position: static;
    opacity: 1;
    transform: none;
    width: 180px;
    margin-left: 0;
  }

  .shift-contents {
    transform: none;
    opacity: 1;
  }
}

.show-hamburger-tablet :global(.dex-nav__left-nav button.dex-site-header__collapse-button) {
  display: flex;
}

.show-hamburger-tablet header:global(.dex-site-header):global(.noPadding) {
  max-width: initial;
}

@media screen and bp-tablet-medium-viewport {
  .show-hamburger-tablet :global(.dex-nav__left-nav button.dex-site-header__collapse-button) {
    display: none;
  }
}
