@value bp-tablet-small-viewport from '../../../../ui/src/lib/styles/values/breakpoints.css';

.in-modal-container {
  display: none;
}

.wide-content {
  --modal-partial-width: 1280px;
  --modal-partial-border-radius: var(--dex-border-radius);

  width: 100vw;
  max-width: var(--modal-partial-width);
  border-radius: var(--modal-partial-border-radius);
}

.padded-sides {
  padding-left: calc(3 * var(--dex-spacing-1x));
  padding-right: calc(3 * var(--dex-spacing-1x));
}

.no-vert-padding {
  --modal-partial-vertical-padding-size: 0;
  --modal-full-vertical-padding-size: 0;
}

.transparent-bg {
  background-color: transparent;
}

.no-scroll {
  overflow: hidden;
}

@media only screen and bp-tablet-small-viewport {
  .wide-content {
    height: fit-content;
  }
}
