.anchor {
  /* this CSS var ensures that we can shift the anchor headings when we have a fixed header that pushes the content */
  top: calc(1 * var(--anchor-heading-top, 0));
  position: absolute;
}

.container {
  visibility: hidden;
  position: relative;
}
