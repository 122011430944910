.code-block {
  --dex-code-block-background-color: var(--core-fill-50-solid-color);
  background-color: var(--dex-code-block-background-color);
  border-color: var(--dex-code-block-border-color);

  display: grid;
  grid-auto-columns: auto;
  overflow: auto;
}

.request {
  --dex-code-block-background-color: var(--dex-core-dark-blue-400);
}

.code-block.request {
  border-color: transparent;
}

.comment-typing-animation :global(.hljs-comment) {
  animation: typing 2s steps(30, end), cursor 2s;
  border-right: 1px solid transparent;
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;
}

.header {
  --dex-code-block-background-color: var(--core-fill-50-solid-color);

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--dex-code-block-background-color);
  border-color: var(--dex-code-block-border-color);
  border-bottom-color: var(--dex-border-color);
}

.header.request {
  --dex-code-block-background-color: var(--dex-core-dark-blue-400);
  border-bottom-color: var(--core-fill-inverse-color);
}

.header.floating {
  position: sticky;
  top: 0;
  height: 0;
}

.main {
  word-break: break-all;
  overflow: auto;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap {
  white-space: pre-wrap;
}

.fill-height {
  height: 100%;
}

@keyframes typing {
  from {
    max-width: 0;
  }
  to {
    max-width: 100%;
  }
}

@keyframes cursor {
  from, to {
    border-color: var(--dex-code-comment-color);
  }
}
