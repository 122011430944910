.icon-color path {
  fill: var(--core-text-10-color);
}

.icon-color-20 path {
  fill: var(--core-text-20-color);
}

.icon-color-30 path {
  fill: var(--core-text-30-color);
}

.icon-disabled-color-20 path {
  fill: var(--core-fill-20-color);
}

.icon-current-color {
  color: var(--core-text-10-color);
}

.icon-current-color-20 {
  color: var(--core-text-20-color);
}

.icon-warning-color path {
  fill: var(--core-warning-fill-color);
}

.icon-critical-color path {
  fill: var(--core-critical-fill-color);
}

.icon-current-color-30 {
  color: var(--core-text-30-color);
}

.icon-fill-current-color path {
  fill: currentColor;
}

.icon-fill-circle-current-color circle {
  fill: currentColor;
}

.icon-delete-fill-current-color:hover path {
  fill: var(--core-red-fill-color);
  fill-opacity: 1;
}

.icon-rotate-180 {
  transform: rotate(180deg);
}

.icon-spinner {
  animation: LoadingSpinner 0.6s linear infinite;
}

.icon-spinner path {
  stroke: var(--core-fill-10-color);
}

@keyframes LoadingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
