@value bp-desktop-medium-viewport, bp-desktop-large-viewport from '../styles/values/breakpoints.css';

.banner {
  position: relative;
}

.content {
  position: relative;
  max-width: 100%;
}

.banner-image {
  display: none;
  position: absolute;
  top: calc(-8 * var(--dex-spacing-1x));
  right: calc(-16 * var(--dex-spacing-1x));
}

.banner-image.custom-image {
  top: calc(-15 * var(--dex-spacing-1x));
  right: calc(-10 * var(--dex-spacing-1x));
}

@media only screen and bp-desktop-medium-viewport {
  .banner-image {
    display: block;
  }

  .content {
    max-width: 420px;
  }
}

@media only screen and bp-desktop-large-viewport {
  .content {
    max-width: 560px;
  }
}
