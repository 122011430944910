@value bp-tablet-small-viewport from '../../../../../ui/src/lib/styles/values/breakpoints.css';

.results {
  overflow: auto;
  max-height: calc(
    100vh - var(--dex-search-input-height) - var(--dex-search-margins) -
      var(--dex-search-key-control-height)
  );
}

.results.shim::after {
  background: linear-gradient(
    var(--dex-background-color-rbga-transparent),
    var(--dex-background-color-rbga)
  );
  bottom: 0;
  content: '';
  height: calc(10 * var(--dex-spacing-1x));
  pointer-events: none;
  position: absolute;
  transition-property: opacity;
  transition-timing-function: linear;
  width: 100%;
  z-index: 1;
}

.divided {
  border-top: 1px solid var(--core-fill-30-color);
  margin-top: 8px;
}

@media only screen and bp-tablet-small-viewport {
  .results.shim::after {
    bottom: calc(15 * var(--dex-spacing-1x));
  }
}
