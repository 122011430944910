@value bp-tablet-small-viewport, bp-tablet-large-viewport from '../../../../../ui/src/lib/styles/values/breakpoints.css';

.tabset + .tab-content + div[data-markdown-heading='2'] {
  margin-top: calc(8 * var(--dex-spacing-1x));
}

ul.tabset {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

p + .tabset {
  margin-top: calc(3 * var(--dex-spacing-1x));
}

.tab.has-image {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(5 * var(--dex-spacing-1x));
}

.tabImage {
  object-fit: contain;
  object-position: left;
}

/* The image appears on top in smaller viewports */
.col2 {
  position: relative;
  order: -1;
  min-height: 170px;
}

.tab.has-image .col2 {
  min-height: var(--markdown-tab-image-height);
}

.tab > img {
  object-fit: cover;
  max-height: 100%;
}

@media only screen and bp-tablet-small-viewport {
  .tab.has-image {
    grid-template-columns: 1fr 1fr;
  }

  .tabImage {
    border-radius: calc(0.75 * var(--dex-spacing-1x));
    object-fit: cover;
    object-position: center;
  }

  .col2 {
    order: 2;
  }
}
