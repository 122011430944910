.icon {
  width: var(--tooltip-min-width, 24px);
  height: var(--tooltip-min-height, 32px);

  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  justify-content: center;
}

.color-10 {
  color: var(--core-fill-10-color);
}

.color-20 {
  color: var(--core-fill-20-color);
}

.color-30 {
  color: var(--core-fill-30-color);
}

/* Legacy hover styling for this icon if used without color variant */
.hover-styling:hover svg {
  color: var(--core-fill-20-color);
}

.color-10.hover-styling:hover svg {
  /* 10% darker. There's no market color below 10 */
  filter: brightness(90%);
}

.color-20.hover-styling:hover svg {
  color: var(--core-fill-10-color);
}

.color-30.hover-styling:hover svg {
  color: var(--core-fill-20-color);
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;

  --tooltip-min-width: 32px;
  --tooltip-min-height: 36px;

  width: var(--tooltip-min-width);
  height: var(--tooltip-min-height);
}
