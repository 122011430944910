@value bp-tablet-small-viewport from '../../../ui/src/lib/styles/values/breakpoints.css';

.badge {
  margin-top: calc(2 * var(--dex-spacing-1x));
}

/* card link out needs to be 24px from the badges 
A more correct statement is `div:has(.badge) + p > .card-link-out`, 
but this will fail on firefox since it doesn't yet support :has */
div + p > .card-link-out {
  margin-top: calc(3 * var(--dex-spacing-1x));
}

.card-layout.row:not(.wrap) > .card,
.card-layout > .card-layout {
  flex: 1 1 10%; /* flex-grow: 1, flex-shrink: 1, flex-basis: 10% */
}

/* We want the left/right/bottom edges of the cards to line up
with the grid. To avoid clipping, we use negative margins to shift the
content over */
.card-layout.grid {
  margin-left: calc(-2 * var(--dex-spacing-1x));

  margin-right: calc(-2 * var(--dex-spacing-1x));

  margin-bottom: calc(-2 * var(--dex-spacing-1x));
}

/* Ensure the space between grid layout and the next section is 64px */
.card-layout.grid + div[data-markdown-heading='2'] {
  margin-top: calc(8 * var(--dex-spacing-1x));
}

/* Grids don't have right or bottom margins */
.card-layout.row:not(.grid) {
  gap: calc(3 * var(--dex-spacing-1x));
}

.card-layout.col:not(.grid) {
  gap: calc(3 * var(--dex-spacing-1x));
}

.card {
  overflow-y: clip;
}

.card.in-tab p > strong {
  font-family: var(--core-type-paragraph-20-font-family);
  font-size: var(--core-type-paragraph-20-size);
  line-height: var(--core-type-paragraph-20-leading);
  letter-spacing: var(--core-type-paragraph-20-tracking);
}

.card.in-tab .badge {
  margin-top: calc(1.5 * var(--dex-spacing-1x));
}

.card-image-offset {
  margin-top: calc(3 * var(--dex-spacing-1x));
  margin-bottom: calc(-18 * var(--dex-spacing-1x));
}

/* Ensure heading is spaced correctly in cards when after an icon*/
.card > div > h2,
.card > div > h3,
.card > div > h4 {
  margin-bottom: calc(1 * var(--dex-spacing-1x));
}

.card > div > img + h2,
.card > div > img + h3,
.card > div > img + h4 {
  margin-top: calc(1.5 * var(--dex-spacing-1x));
  margin-bottom: calc(1 * var(--dex-spacing-1x));
}

@media only screen and bp-tablet-small-viewport {
  .badge {
    margin-top: calc(3 * var(--dex-spacing-1x));
  }

  /* grid layouts have no right margin */
  .card-layout.row:not(.grid) {
    gap: calc(4 * var(--dex-spacing-1x));
  }

  .card-layout.col:not(.grid) {
    gap: calc(4 * var(--dex-spacing-1x));
  }
}
