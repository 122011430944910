.popover {
  background-color: var(--core-surface-5-color);
}

.row:hover,
.row.selected {
  background-color: var(--core-emphasis-30-color);
}

.selected .primary {
  color: var(--core-emphasis-text-color);
}

.selected .secondary {
  color: var(--core-text-10-color);
}

.row-interactable {
  width: 100%;
  text-align: left;
}
