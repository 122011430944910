.limit-height {
  max-height: 384px;
}

.bottom-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 0;
  position: sticky;
  bottom: 0;
}

.bar-position {
  position: absolute;
  bottom: calc(2 * var(--dex-spacing-1x));
}

.footer-action {
  right: calc(2 * var(--dex-spacing-1x));
}

.loading {
  /* Based on the max lines before collapsing */
  min-height: calc(16 * 22px);
}
