.doc-badge {
  background-color: var(--core-fill-40-color);
  text-decoration: none;
  word-break: break-word;
}

.doc-badge.href:hover {
  background-color: var(--doc-page-color-bg-theme, --core-blue-text-color);
  color: var(--doc-page-color-text-theme, --core-text-inverse-color);
}
