.button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;

  background-color: transparent;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
}

.no-border {
  border: none;
  border-radius: 6px;
}

.button-padding {
  padding: 0;
}

.button:focus-visible {
  outline: transparent;
  border: none;
}

.button:disabled {
  cursor: not-allowed;
}
