a.sidebar-link.sidebar-container,
a.sidebar-link.sidebar-container {
  color: var(--core-text-20-color);
  display: block;
  width: 100%;
  text-decoration: none;
  outline: transparent;
}

a.sidebar-link.sidebar-container.selected {
  color: var(--core-emphasis-text-color);
}

a.sidebar-link.sidebar-container:hover,
a.sidebar-link.sidebar-container:focus-visible {
  text-decoration: none;
  color: var(--core-emphasis-text-color);
}

.sidebar-link-icon {
  display: flex;
}
