.code {
  font-size: 13px;
  counter-reset: codeblock;
  color: var(--dex-code-default-color);
}

.line-numbers span[data-line-number]::before {
  display: inline-block;
  counter-increment: codeblock;
  content: counter(codeblock);
  margin-right: var(--dex-spacing-1x);

  font-family: var(--dex-code-font-family);
  color: var(--core-fill-20-color);
  width: var(--line-number-length);
  text-align: end;

  /* P20 styles. Have to use CSS vars instead of the component here because
   * line numbers are filled in a CSS ::before selector using `counter-increment`
  */
  font-size: var(--core-type-paragraph-20-size);
  line-height: var(--core-type-paragraph-20-leading);
  letter-spacing: var(--core-type-paragraph-20-tracking);
  font-weight: var(--core-type-paragraph-20-weight);
  text-transform: var(--core-type-paragraph-20-case);
}

.popover {
  background-color: var(--core-surface-5-color);
  padding: calc(2 * var(--dex-spacing-1x));
}

.hidden {
  display: none;
}
