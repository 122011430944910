.item {
  display: flex;
  flex-direction: row;
  position: relative;

  flex: 1;
  max-width: 100%;

  cursor: pointer;
  text-decoration: none;
  outline: none;
  line-height: normal;
}

.item:first-of-type {
  margin-top: var(--dex-spacing-1x);
}

.item.try-focus,
.item:hover,
.item:focus, /* need this because Safari doesn't always apply :focus-visible as expected */
.item:focus-visible {
  background-color: var(--core-emphasis-40-color);
}

.text-container {
  /* MarketTooltip overrides */
  --tooltip-min-width: 0px;
  --tooltip-min-height: 0px;
}

.title {
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: baseline;
}

.name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  padding: 0;
  margin: 0;
  border: 0;
  text-decoration: none;
}

.item.try-focus .name,
.item:hover .name,
.item:focus .name, /* need this because Safari doesn't always apply :focus-visible as expected */
.item:focus-visible .name {
  color: var(--core-emphasis-fill-color);
}

.domain {
  word-break: keep-all;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.item.try-focus .description,
.item:hover .description,
.item:focus .description, /* need this because Safari doesn't always apply :focus-visible as expected */
.item:focus-visible .description {
  color: var(--core-text-10-color);
}

.category {
  /* This is the width at which the longest badge name (Deprecated endpoint) fits */
  width: 224px;
  white-space: nowrap;

  align-self: center;
  margin-left: auto;

  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

.badge {
  background-color: var(--core-fill-40-color);
}

.item.try-focus .badge,
.item:hover .badge,
.item:focus .badge, /* need this because Safari doesn't always apply :focus-visible as expected */
.item:focus-visible .badge {
  color: var(--core-text-inverse-color);
  background-color: var(--core-emphasis-fill-color);
}

.breadcrumb-chevron path {
  fill: var(--core-text-30-color);
}

.item.try-focus .breadcrumb-chevron path,
.item:hover .breadcrumb-chevron path,
.item:focus .breadcrumb-chevron path,
.item:focus-visible .breadcrumb-chevron path {
  fill: var(--core-text-20-color);
}

.breadcrumb {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
