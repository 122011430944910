.outer {
  position: relative;
  /* Since the max width needs to be a real number instead of a %, we will calculate
  the max width based on the grid margins and current page width */
  max-width: var(--dex-table-max-width, 100%);
}

.container {
  overflow-x: auto;
}

.container.overflow-visible {
  overflow-x: visible;
}

.table {
  border-spacing: 0;
  width: 100%;
}

.table thead {
  background: var(--core-surface-5-color);
}

.table:not(.sticky-header) thead::before,
.table:not(.sticky-header) thead::after {
  content: none;
}

.table.sticky-header thead {
  position: sticky;
  top: var(--table-header-sticky-position, 0);
}

.table th.left,
.table td.left {
  text-align: left;
}

.table th.right,
.table td.right {
  text-align: right;
}

.table th.center,
.table td.center {
  text-align: center;
}

.table th.justify,
.table td.justify {
  text-align: justify;
}

.table td {
  vertical-align: top;
}
