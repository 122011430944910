/* Original shim styling */
.shim::after {
  background: linear-gradient(
    var(--dex-background-color-rbga-transparent),
    var(--dex-background-color-rbga)
  );
  bottom: 0;
  content: '';
  height: calc(10 * var(--dex-spacing-1x));
  pointer-events: none;
  position: absolute;
  transition-property: opacity;
  transition-timing-function: linear;
  width: 100%;
  z-index: 1;
}

/* These set up the transitions for fading in/out */
.setup:not(.light) {
  border: 1px solid transparent;
}

.setup::after,
.setup::before {
  content: '';
  opacity: 0;
  transition: opacity 0.25s linear;
}

.shim-horizontal-left {
  border-left-color: var(--core-divider-20-color);
}

.shim-horizontal-right {
  border-right-color: var(--core-divider-20-color);
}

.shim-horizontal-left::before,
.shim-horizontal-right::after {
  background: linear-gradient(270deg, var(--dex-shim-linear-gradient-colors));

  top: 0;
  width: calc(5 * var(--dex-spacing-1x));
  height: 100%;
  pointer-events: none;
  position: absolute;
  opacity: 1;
}

.shim-horizontal-left::before {
  left: 0;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.shim-horizontal-right::after {
  right: 0;
}

.shim-horizontal-left.light::before,
.shim-horizontal-right.light::after {
  background: linear-gradient(
    90deg,
    var(--dex-shim-light-linear-gradient-colors)
  );

  width: calc(11 * var(--dex-spacing-1x));

  z-index: 1;
}

.shim-vertical-top {
  border-top-color: var(--core-divider-20-color);
}

.shim-vertical-bottom {
  border-bottom-color: var(--core-divider-20-color);
}

.shim-vertical-bottom::after {
  background: linear-gradient(180deg, var(--dex-shim-linear-gradient-colors));
  content: '';
  height: calc(5 * var(--dex-spacing-1x));
  pointer-events: none;
  position: absolute;
  width: 100%;
  opacity: 1;
}
