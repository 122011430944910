.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--core-surface-10-color);
}

.button:hover {
  background-color: var(--core-surface-20-color);
}

.link {
  user-select: none;
}

a.link.button,
a.link.button:hover,
a.link.button:visited {
  text-decoration: none;
}

.round {
  width: calc(4 * var(--dex-spacing-1x));
  height: calc(4 * var(--dex-spacing-1x));

  border-radius: 16px;
}
