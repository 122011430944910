.container {
  background-color: var(--core-fill-50-color);

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.keyboard-control {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}

.control-text {
  color: var(--core-text-20-color);
}

.icon-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.esc {
  background-color: var(--core-fill-40-color);
  color: var(--core-text-20-color);
  font-size: 10px;
}

.last {
  margin-left: auto;
}
