.text {
  text-decoration-style: dashed;
  text-decoration-color: var(--core-text-20-color);
  text-decoration-thickness: 1px;
  text-underline-position: under;
  text-underline-offset: 1px;
  text-decoration-line: underline;
  cursor: default;

  --tooltip-text-normal-state-color: var(--core-text-10-color);

  /* Allow the text components to control tooltip text styling as well, since it's masked by a CSS variables */
  font-weight: var(--dex-tooltip-text-weight);
  font-size: var(--dex-tooltip-text-size);
  line-height: var(--dex-tooltip-text-leading);
  letter-spacing: var(--dex-tooltip-text-tracking);
  text-transform: var(--dex-tooltip-text-case);
}

.text:hover {
  text-decoration-color: var(--core-text-10-color);
}

.tooltip {
  --tooltip-minimum-width: 0px;
  --tooltip-minimum-height: var(--core-type-paragraph-30-leading);
}

.hidden {
  display: inline-block;
  visibility: hidden;
  width: 0px;
}
