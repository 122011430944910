.container {
  background-color: var(--core-surface-5-color);

  display: flex;
  align-items: center;
}

.input {
  background-color: var(--core-surface-5-color);
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;

  font-family: var(--core-type-paragraph-30-font-family);
  font-size: var(--core-type-paragraph-30-size);
  line-height: var(--core-type-paragraph-30-leading);
  font-weight: var(--core-type-paragraph-30-weight);
  letter-spacing: var(--core-type-paragraph-30-tracking);
  text-transform: var(--core-type-paragraph-30-case);
  color: var(--core-text-10-color);
}

.input::placeholder {
  color: var(--core-text-20-color);
}

.input:focus {
  outline: none;
}
