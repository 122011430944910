.markdown-resource-info.resource a {
  color: var(--core-emphasis-text-color);
}

.markdown-resource-info.resource a:hover {
  text-decoration: none;
}

/* General override for resource info cards */
.markdown-resource-info p + p {
  margin-top: 0;
}

.grid.layout-narrow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: calc(4 * var(--dex-spacing-1x));
  column-gap: calc(3 * var(--dex-spacing-1x));
}

.layout-narrow > .markdown-resource-info:not(:last-child) {
  margin-right: calc(4 * var(--dex-spacing-1x));
}
