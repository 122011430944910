@value bp-tablet-small-viewport from '../styles/values/breakpoints.css';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.container > *:not(:first-child) {
  margin-left: calc(1.5 * var(--dex-spacing-1x));
}

.image {
  border: var(--dex-border-line);
  border-radius: 6px;

  padding: calc(0.75 * var(--dex-spacing-1x));
}

@media only screen and bp-tablet-small-viewport {
  .container {
    justify-content: center;
  }
}
