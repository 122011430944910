.code-highlight {
  --dex-code-comment-color: var(--core-fill-20-color);
  --dex-code-keyword-color: var(--core-red-fill-color);
  --dex-code-number-color: var(--core-red-fill-color);
  --dex-code-variable-color: var(--core-teal-text-color);
  --dex-code-tag-color: var(--core-red-fill-color);
  --dex-code-attribute-color: var(--core-text-20-color);
  --dex-code-formula-color: var(--core-text-20-color);
  --dex-code-addition-color: var(--core-text-20-color);
  --dex-code-deletion-color: var(--core-red-fill-color);
  --dex-code-default-color: var(--core-text-10-color);
}

.code-highlight :global .hljs {
  display: block;
  overflow-x: auto;
}

.code-highlight :global .hljs-comment,
.code-highlight :global .hljs-quote {
  color: var(--dex-code-comment-color);
}

.code-highlight :global .hljs-comment {
  line-height: 24px;
}

.code-highlight :global .hljs-keyword,
.code-highlight :global .hljs-selector-tag,
.code-highlight :global .hljs-attr,
.code-highlight :global .hljs-regexp,
.code-highlight :global .hljs-link {
  color: var(--dex-code-keyword-color);
}

.code-highlight :global .hljs-number,
.code-highlight :global .hljs-literal {
  color: var(--dex-code-number-color);
}

.code-highlight :global .hljs-variable,
.code-highlight :global .hljs-template-variable,
.code-highlight :global .hljs-string,
.code-highlight :global .hljs-meta {
  color: var(--dex-code-variable-color);
}

.code-highlight :global .hljs-name,
.code-highlight :global .hljs-title,
.code-highlight :global .hljs-tag,
.code-highlight :global .hljs-symbol,
.code-highlight :global .hljs-bullet,
.code-highlight :global .hljs-section,
.code-highlight :global .hljs-class,
.code-highlight :global .hljs-title,
.code-highlight :global .hljs-type,
.code-highlight :global .hljs-built_in,
.code-highlight :global .hljs-builtin-name,
.code-highlight :global .hljs-params,
.code-highlight :global .hljs-selector-id,
.code-highlight :global .hljs-selector-class {
  color: var(--dex-code-tag-color);
}

.code-highlight :global .hljs-attribute,
.code-highlight :global .hljs-subst {
  color: var(--dex-code-attribute-color);
}

.code-highlight :global .hljs-formula {
  background-color: var(--dex-code-formula-color);
  font-style: italic;
}

.code-highlight :global .hljs-addition {
  background-color: var(--dex-code-addition-color);
}

.code-highlight :global .hljs-deletion {
  background-color: var(--dex-code-deletion-color);
}

.code-highlight :global .hljs-name,
.code-highlight :global .hljs-doctag,
.code-highlight :global .hljs-strong {
  font-weight: 400;
}

.code-highlight :global .hljs-emphasis {
  font-style: italic;
}

.bash :global .hljs-attr,
.json :global .hljs-attr,
.curl :global .hljs-attr {
  color: var(--dex-code-default-color);
}
